import React from 'react'
import { PageProps } from 'gatsby'
import {
  Head,
  Hero,
  Contact,
  MosaicSection,
  Footer,
  WhatsApp,
} from '~/components'
import Banner from '~/assets/img/Banner-contato.jpg'
import BeABroker from '~/assets/img/Seja-um-corretor.jpg'
import { useScroll } from '~/hooks'

const ContactPage = ({ location }: PageProps) => {
  useScroll()

  return (
    <>
      <Head
        location={location}
        title={`Contato - ${process.env.GATSBY_SITE_NAME}`}
      />
      <Hero image={Banner} title="Contato" subtitle="Fale com a Aikon" />
      <Contact />
      <MosaicSection
        title="Seja um corretor"
        description="Faça seu cadastro e fique por dentro de todos os lançamentos da Aikon Empreendimentos."
        buttonLabel="Cadastre-se"
        url="/corretor/"
        image={BeABroker}
      />
      <Footer />
      <WhatsApp />
    </>
  )
}

export default ContactPage
